import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/Error.css';

const types = {
  empty: {
    title: 'Sorry, this PhileSpace isn\'t ready yet.',
    getDescription: () => <p>Please come back at a later date.</p>,
  },
  private: {
    title: 'This PhileSpace gallery is private',
    getDescription: (user) => (user
      ? (
        <p>
          <strong className="user-email">
            {/* eslint-disable-next-line react/destructuring-assignment */}
            {user.email}
            &nbsp;
          </strong>
          <span>
            does not have permission to view this gallery. Please request access
            to view this collection.
          </span>
        </p>
      )
      : (<p>Please sign into your account to view the contents of this collection.</p>)
    ),
  },
  graphql: {
    title: 'Sorry, something went wrong.',
    getDescription: (err) => {
      // TODO: This structure might be specific to network errors coming from
      // Apollo vs. query errors, but I don't have a good test case for those
      // eslint-disable-next-line react/destructuring-assignment
      const result = err.networkError?.result;

      console.error(err);

      return (
        <p>
          {
            result && result?.errors
              ? result.errors[0].message.replace('Context creation failed: ', '')
              // eslint-disable-next-line react/destructuring-assignment
              : (err.message || err.networkError?.message || 'An unknown error occurred.')
          }
        </p>
      );
    },
  },
  missing: {
    title: 'Sorry, we can’t find that content.',
    getDescription: () => (
      <p>
        Please double-check the URL or visit the whole
        {/* We need the nbsp on the same line or we end up with two spaces */}
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        &nbsp;<a href="/" className="secondary-color">collection</a>.
      </p>
    ),
  },
  outdatedBrowser: {
    title: 'Outdated Browser',
    getDescription: () => (
      <>
        <p>
          We have detected that you are using an outdated browser that does not
          support this visualization.
        </p>
        <p>
          For the best experience, we recommend using
          {' '}
          <a href="https://www.google.com/chrome/">Google Chrome</a>
          .
        </p>
      </>
    ),
  },
};

const Error = ({ type, content, unknownError }) => {
  let title;
  let description;

  if (unknownError || !type || !types[type]) {
    title = 'An unrecoverable error occurred';
    description = 'Please try refreshing the page.';
  } else {
    const err = types[type];

    title = err.title;
    description = err.getDescription(content);
  }

  return (
    <div className="error-page">
      <div className="centered">
        <h1>{title}</h1>
        <div className="description">
          {description}
        </div>
      </div>
    </div>
  );
};

Error.propTypes = {
  type: PropTypes.oneOf(['empty', 'private', 'graphql', 'missing']),
  content: PropTypes.shape({}),
  unknownError: PropTypes.shape({}),
};

Error.defaultProps = {
  type: null,
  content: null,
  unknownError: null,
};

export default Error;
