const english = {
  Carousel: {
    Slide: {
      pdfLinkTextDefault: 'To access links in this PDF, <br /> please open it in a new tab',
      audioSupport: 'Your browser does not support the audio tag.',
      pdfLinkTextSafari: 'Open PDF in new tab',
    },
  },
  CategorySelect: {
    widget: {
      label: 'Category Select',
      fields: {
        title: {
          default: 'Select a Category',
        },
      },
      tutorialSlides:
      {
        title: 'Categories',
        body: 'Use these icons to sort the cards in the gallery by the categories shown.',
      },
    },
  },
  CloseIcon: {
    title: 'Close',
  },
  Dialog: {
    missingContentDialog: {
      timeline: 'Sorry, there are no vignettes for this timeframe.',
      search: 'Sorry, nothing matches that term. \nDouble-check the spelling or try a broader or related term.',
      category: 'Sorry, there are no vignettes for this category.',
      stage: 'Sorry, there are no vignettes for this stage',
    },
  },
  /* We are not implementing these error translations at the moment
   * because the error texts are getting used outside the CustomI18nextProvider
   */
  Error: {
    defaultTitle: 'An unrecoverable error occurred',
    defaultDescription: 'Please try refreshing the page.',
    types: {
      empty: {
        title: 'Sorry, this PhileSpace isn\'t ready yet.',
        description: 'Please come back at a later date.',
      },
      private: {
        title: 'This PhileSpace gallery is private',
        descriptionUser: 'does not have permission to view this gallery. Please request access to view this collection.',
        descriptionNoUser: 'Please sign into your account to view the contents of this collection.',
      },
      graphql: {
        title: 'Sorry, something went wrong.',
        description: 'An unknown error occurred.',
      },
      missing: {
        title: 'Sorry, we can’t find that content.',
        description: 'Please double-check the URL or visit the whole &nbsp;<a href="/" className="secondary-color">collection</a>.',
      },
      outdatedBrowser: {
        title: 'Outdated Browser',
        description1: 'We have detected that you are using an outdated browser that does not support this visualization.',
        description2: 'For the best experience, we recommend using',
      },
    },
  },
  FootNote: {
    footNote: {
      poweredBy: 'Powered by Studio Energy',
      patentInfo: 'Design patents registered in the U.S., Canada and European Community',
      contactUs: 'Contact Us',
    },
  },
  Gallery: {
    components: {
      useExpanded: {
        IconGroup: {
          favourite: 'FAVOURITE',
          seeMore: 'SEE MORE',
          listen: 'LISTEN',
          stop: 'STOP',
          buyNow: 'BUY NOW',
          location: 'LOCATION',
          share: 'SHARE',
        },
      },
    },
    layouts: {
      grid: {
        label: 'Grid',
        description: 'Vignettes are laid out in a grid, starting from the top-left corner.',
      },
      linearHorizontal: {
        label: 'Row',
        description: 'Vignettes are laid out in a single row.',
      },
      linearVertical: {
        label: 'Column',
        description: 'Vignettes are laid out in a single column.',
      },
      spiral: {
        label: 'Shuffled',
        description: 'Vignettes are laid out in a shuffled pattern, starting from the center.',
      },
      spiralGrid: {
        label: 'Grid',
        description: 'Vignettes are laid out in a grid, starting from the center and spiraling outward.',
      },
    },
    widget: {
      label: 'Gallery',
      tutorialSlides: {
        slide1: {
          title: 'Cards',
          body: 'There are many cards in the gallery. You can hover over them individually or click and drag the whole gallery around to see them all.',
        },
        slide2: {
          title: 'Cards',
          body: 'To read a card, select it. Once it\'s opened, you may see buttons along the left for additional actions.',
        },
      },
    },
  },
  GallerySearch: {
    search: 'Search',
    result: 'Result',
  },
  Header: {
    goToGallery: 'Go to Gallery',
    signIn: 'Sign In',
    gallery: 'Gallery',
    stories: 'Stories',
    sessions: 'Sessions',
    about: 'About',
    blog: 'Blog',
    contact: 'Contact',
    store: 'Store',
    connect: 'Connect with us',
    PopoutMenu: {
      signOut: 'Sign out',
    },
  },
  LoadingScreen: {
    loadingScreenText: 'Server is under heavy load. Please wait...',
  },
  // This is found in common-ui
  Map: {
    map: 'MAP',
  },
  MobileListView: {
    noResultsMessage: 'No results',
  },
  MobileSearchBar: {
    search: 'Search',
  },
  MobileWidgets: {
    MobileCategoriesWidget: {
      title: 'Categories',
    },
    MobileLanguagesWidget: {
      title: 'Language',
    },
    MobileStagesWidget: {
      title: 'Stages',
    },
  },
  RelatedStories: {
    widget: {
      label: 'Related Stories',
      fields: {
        title: {
          default: 'Related Stories',
        },
      },
      tutorialSlides:
      {
        title: 'Related stories',
        body: 'When you hover over or select a card, its related readings appear here. Select a cover to find out more.',
      },
    },
  },
  RoomPopup: {
    goToGalleryText: 'To see the gallery this collection is part of, select <strong>Go to Gallery</strong> in the upper-right corner.',
    viewCollection: 'View Collection',
  },
  StageWidget: {
    widget: {
      label: 'Stage',
      fields: {
        title: {
          default: 'Stage',
        },
      },
      tutorialSlides:
      {
        title: 'Stages',
        body: 'Use these buttons to filter the gallery by the stage they are associated with. The Curves indicate the relative number of items per stage. In the desktop version a range of stages can be selected by clicking and holding on a stage after another is already selected.',
      },
    },
  },
  Timeline: {
    getTimelineBars: {
      errorPart1: 'Timeline does not support a bar size of',
      errorPart2: 'years',
    },
    widget: {
      label: 'Timeline',
      fields: {
        title: {
          default: 'Timeline',
        },
      },
      errorMessages: {
        endYear: {
          validate: 'Must be later than start year',
        },
      },
      tutorialSlides: {
        title: 'Timeline',
        body: 'To see items from a specific time period, select the date range in the timeline.',
      },
    },
  },
  Toolbar: {
    items: {
      search: 'Search',
      favourite: 'Favourite',
      layout: 'Layout',
      tutorial: 'Tutorial',
      reset: 'Reset',
    },
  },
  Tutorial: {
    toolbarSlide: {
      searchItem: {
        title: 'Search',
        body: 'Enter a term to search the entire collection',
      },
      layoutItem: {
        title: 'Layout',
        body: 'Choose how you view the collection',
      },
      favouriteItem: {
        title: 'Favourite',
        body: 'Log in to favourite cards and save them to your account',
      },
      tutorialItem: {
        title: 'Tutorial',
        body: 'Launch the tutorial',
      },
      resetItem: {
        title: 'Reset',
        body: 'Clear all active sorts or search results',
      },
    },
    slides: {
      defaultActions: {
        text1: 'Got it',
        text2: 'Skip the tour',
      },
      first: {
        title: 'Let’s show you around',
        body: 'This short tour shows you the different parts of the gallery and how you can use them.',
        actions: {
          text1: 'Take the tour',
          text2: 'Skip the tour',
        },
      },
      last: {
        title: 'Toolbar',
      },
    },
  },
  VignetteGallery: {
    relatedVignettesLabel: 'Related Vignettes',
    relatedVignettes: {
      Error: 'Error loading related vignettes',
    },
  },
  WidgetContainer: {
    ErrorPart1: 'Widget',
    ErrorPart2: 'is missing the "widget" property',
  },
};

export default english;
