import React, { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';

import Error from './components/Error';

import '@philespace/common-ui/lib/styles/colors.css';
import '@philespace/common-ui/lib/styles/fonts.css';
import '../common/static/assets/i18n';

if (process.env.NODE_ENV !== 'production') {
  window.injectedEnv = devInjectedEnv; // eslint-disable-line no-global-assign, no-undef
}

let sentryEnvironment;

if (process.env.NODE_ENV === 'production') {
  if (window.location.toString().includes('staging.philespace.vizworxdev.com')) {
    sentryEnvironment = 'staging';
  } else if (window.location.toString().includes('philespace.localhost')) {
    sentryEnvironment = 'preview';
  } else {
    sentryEnvironment = 'production';
  }

  Sentry.init({
    release: `client@${window.injectedEnv.imageVersion}`,
    dsn: window.injectedEnv.sentryDsn,
    environment: sentryEnvironment,
    integrations: [
      Sentry.browserTracingIntegration(),
    ],
    tracesSampleRate: 1.0,
  });
} else {
  // eslint-disable-next-line no-console
  console.log('Running in test environment, not loading Sentry');
}

// Used to store namespaced debug state/functions
globalThis.philespace = {};

const container = document.getElementById('react-root');
const root = createRoot(container);

if (!document.documentMode) {
  // This has to be a require, otherwise IE will complain about polyfills
  const App = lazy(() => import(/* webpackChunkName: 'App' */ './App'));

  root.render(<Suspense fallback={null}><App /></Suspense>);
} else {
  root.render(<Error type="outdatedBrowser" />);
}
