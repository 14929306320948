const French = {
  Carousel: {
    Slide: {
      pdfLinkTextDefault: 'Pour accéder aux liens dans ce PDF, <br /> veuillez l’ouvrir dans un nouvel onglet',
      audioSupport: 'Votre navigateur ne prend pas en charge la balise audio.',
      pdfLinkTextSafari: 'Ouvrez un PDF dans un nouvel onglet',
    },
  },
  CategorySelect: {
    widget: {
      label: 'Sélection de la catégorie',
      fields: {
        title: {
          default: 'Sélectionnez une catégorie',
        },
      },
      tutorialSlides:
      {
        title: 'Catégories',
        body: 'Utilisez ces icones pour trier les cartes de la galerie selon les catégories montrées.',
      },
    },
  },
  CloseIcon: {
    title: 'Fermer',
  },
  Dialog: {
    missingContentDialog: {
      timeline: 'Désolé, il n’y a pas de vignettes pour cette période.',
      search: 'Désolé. Il n’y a pas de correspondance à ce mot. \nRevérifiez l’orthographe ou essayez un sujet plus large ou associé.',
      category: 'Désolé, il n’y a pas de vignettes pour cette catégorie.',
      stage: 'Désolé, il n’y a pas de vignettes pour cette étape.',
    },
  },
  /* We are not implementing these error translations at the moment
   * because the error texts are getting used outside the CustomI18nextProvider
   */
  Error: {
    defaultTitle: 'Une erreur irrécupérable s’est produite',
    defaultDescription: 'Veuillez essayer de rafraîchir la page.',
    types: {
      empty: {
        title: 'Désolé, ce PhileSpace n\'est pas encore prêt.',
        description: 'Veuillez revenir à une date ultérieure.',
      },
      private: {
        title: 'Cette galerie PhileSpace est privée',
        descriptionUser: 'n\'a pas la permission de voir cette galerie. Veuillez demander l\'accès pour voir cette collection.',
        descriptionNoUser: 'Veuillez vous connecter à votre compte pour voir le contenu de cette collection.',
      },
      graphql: {
        title: 'Désolé, quelque chose s\'est mal passé.',
        description: 'Une erreur inconnue est survenue.',
      },
      missing: {
        title: 'Désolé. Nous ne trouvons pas le contenu.',
        description: 'Veuillez double-cliquer sur l’URL ou visitez toute la &nbsp;<a href="/" className="secondary-color">collection</a>.',
      },
      outdatedBrowser: {
        title: 'Navigateur obsolète',
        description1: 'Nous avons détecté que vous utilisez un navigateur obsolète qui ne prend pas en charge cette visualisation.',
        description2: 'Pour une expérience optimale, nous vous recommandons d\'utiliser',
      },
    },
  },
  FootNote: {
    footNote: {
      poweredBy: 'Réalisé par Studio Energy',
      patentInfo: 'Brevets de conception enregistrés aux États-Unis, au Canada et dans la Communauté européenne',
      contactUs: 'Contactez nous',
    },
  },
  Gallery: {
    components: {
      useExpanded: {
        IconGroup: {
          favourite: 'PRÉFÉRÉE',
          seeMore: 'EN VOIR PLUS',
          listen: 'ÉCOUTEZ',
          stop: 'ARRÊTER',
          buyNow: 'ACHETEZ MAINTENANT',
          location: 'LIEU',
          share: 'PARTAGEZ',
        },
      },
    },
    layouts: {
      grid: {
        label: 'Réseau',
        description: 'Les vignettes sont disposées dans une grille, en partant du coin supérieur gauche.',
      },
      linearHorizontal: {
        label: 'Rang',
        description: 'Les vignettes sont disposées sur une seule rangée.',
      },
      linearVertical: {
        label: 'Colonne',
        description: 'Les vignettes sont disposées sur une seule colonne.',
      },
      spiral: {
        label: 'Déplacé',
        description: 'Les vignettes sont disposées selon un motif mélangé, en partant du centre.',
      },
      spiralGrid: {
        label: 'Réseau',
        description: 'Les vignettes sont disposées dans une grille, en partant du centre et en spirale vers l\'extérieur.',
      },
    },
    widget: {
      label: 'Galerie',
      tutorialSlides: {
        slide1: {
          title: 'Cartes',
          body: 'Il y a de nombreuses cartes dans la galerie. Vous pouvez les survoler individuellement ou cliquer et faire glisser toute la galerie pour voir toutes les cartes.',
        },
        slide2: {
          title: 'Cartes',
          body: 'Pour lire une carte, sélectionne. Sélectionnez-la. Une fois qu’elle est ouverte, vous verrez des boutons sur la gauche pour des actions supplémentaires.',
        },
      },
    },
  },
  GallerySearch: {
    search: 'Recherche',
    result: 'Résultat',
  },
  Header: {
    goToGallery: 'Allez à la galerie',
    signIn: 'S\'identifier',
    gallery: 'Galerie',
    stories: 'Histoires',
    sessions: 'Séances',
    about: 'À propos',
    blog: 'Blog',
    contact: 'Contact',
    store: 'Magasin',
    connect: 'Connectez-vous avec nous',
    PopoutMenu: {
      signOut: 'S\'inscrire',
    },
  },
  LoadingScreen: {
    loadingScreenText: 'Le serveur est très sollicité. Veuillez patienter...',
  },
  // This is found in common-ui
  Map: {
    map: 'PLAN',
  },
  MobileListView: {
    noResultsMessage: 'Pas de résultats',
  },
  MobileSearchBar: {
    search: 'Recherche',
  },
  MobileWidgets: {
    MobileCategoriesWidget: {
      title: 'Catégories',
    },
    MobileLanguagesWidget: {
      title: 'Langue',
    },
    MobileStagesWidget: {
      title: 'Étapes',
    },
  },
  RelatedStories: {
    widget: {
      label: 'Histoires liées',
      fields: {
        title: {
          default: 'Histoires liées',
        },
      },
      tutorialSlides:
        {
          title: 'Histoires liées',
          body: 'Lorsque vous survolez ou sélectionnez une carte, ses lectures associées apparaissent ici. Sélectionnez une couverture pour en savoir plus.',
        },
    },
  },
  RoomPopup: {
    goToGalleryText: 'Pour voir la galerie dont cette collection fait partie, sélectionnez <strong>Aller à la Galerie</strong> dans le coin supérieur droit.',
    viewCollection: 'Voyez la collection',
  },
  StageWidget: {
    widget: {
      label: 'Étapes',
      fields: {
        title: {
          default: 'Étapes',
        },
      },
      tutorialSlides:
        {
          title: 'Étapes',
          body: 'Utilisez ces boutons pour filtrer la galerie en fonction de l\'étape à laquelle ils sont associés. Les courbes indiquent le nombre relatif d\'éléments par étape. Dans la version de bureau, une gamme d\'étapes peut être sélectionnée en cliquant et en maintenant une étape après qu\'une autre est déjà sélectionnée.',
        },
    },
  },
  Timeline: {
    getTimelineBars: {
      errorPart1: 'La ligne de temps ne prend pas en charge une taille de barre de ',
      errorPart2: 'années',
    },
    widget: {
      label: 'Chronologie',
      fields: {
        title: {
          default: 'Chronologie',
        },
      },
      errorMessages: {
        endYear: {
          validate: 'Doit être postérieur à l\'année de début',
        },
      },
      tutorialSlides: {
        title: 'Chronologie',
        body: 'Pour voir les articles d’une période spécifique, sélectionnez une plage de date dans la chronologie.',
      },
    },
  },
  Toolbar: {
    items: {
      search: 'Recherche',
      favourite: 'Favoris',
      layout: 'Mise en page',
      tutorial: 'Tutoriel',
      reset: 'Réinitialisation',
    },
  },
  Tutorial: {
    toolbarSlide: {
      searchItem: {
        title: 'Recherche',
        body: 'Saisissez un mot pour chercher la collection entière',
      },
      layoutItem: {
        title: 'Mise en page',
        body: 'Choisissez comment vous souhaitez voir la collection',
      },
      favouriteItem: {
        title: 'Préférée',
        body: 'Connectez-vous aux cartes préférées et enregistrez-les sur votre compte',
      },
      tutorialItem: {
        title: 'Tutoriel',
        body: 'Démarrez le tutoriel',
      },
      resetItem: {
        title: 'Réinitialisation',
        body: 'Effacer tous les tris ou résultats de recherche actifs',
      },
    },
    slides: {
      defaultActions: {
        text1: 'J’ai compris',
        text2: 'Passez la visite',
      },
      first: {
        title: 'Laissez-nous vous montrer les alentours',
        body: 'Cette courte visite vous montre les différentes parties de la galerie et comment vous pouvez les utiliser.',
        actions: {
          text1: 'Faites la visite',
          text2: 'Passez la visite',
        },
      },
      last: {
        title: 'Barre d\'outils',
      },
    },
  },
  VignetteGallery: {
    relatedVignettesLabel: 'Vignettes Associées',
    relatedVignettes: {
      Error: 'Erreur de chargement des vignettes concernées',
    },
  },
  WidgetContainer: {
    ErrorPart1: 'Widget',
    ErrorPart2: 'n\'a pas la propriété "widget"',
  },
};

export default French;
