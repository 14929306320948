import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enLocalization from './en';
import frLocalization from './fr';

// Init i18n with the language files. Set the language to be the tenants locale
i18n
  .use(initReactI18next)
  .init({
    lng: 'en-US',
    fallbackLng: 'en-US',
    resources: {
      'en-US': { translation: enLocalization },
      'fr-CA': { translation: frLocalization },
    },
    // Something to do with rendering.
    // Not entirely sure what it is. React complains if this isn't false.
    react: { useSuspense: false },
    interpolation: { escapeValue: false },
  });

export default i18n;

